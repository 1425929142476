@import './terminal.scss';
@import './highlight.css';

body {
  margin: 0;
  font-family: var(--font-stack);
}

pre {
  padding: 0;
  overflow-x: auto;

  code {
    padding: 1em;
    overflow: visible;
    font-family: var(--mono-font-stack);
    color: var(--global-font-color);
  }
}

img {
  max-width: 100%;
  width: auto;
}