.blog-header {
  .command-line {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: var(--mono-font-stack);

    .ps1 {
      background: none;
      color: var(--primary-color);

      &:before {
        font-icon: url('../icons/c0ldcat.svg');
        vertical-align: middle;
      }

      &::after {
        content: '>';
        display: inline-block;
        margin: 0 .5em;
        color: var(--secondary-color);
      }

      &:hover {
        text-decoration: none;
        background: none;
        color: var(--primary-color);
        cursor: pointer;
      }

      &.rejected {
        &:before {
          font-icon: url('../icons/fail.svg');
          color: var(--error-color);
        }
        &:hover {
          &::before {
            font-icon: url('../icons/retry.svg');
            vertical-align: middle;
          }
        }
      }
    }

    .command-pipeline {
      .command {
        &:not(:first-child) {
          &::before {
            content: ' | ';
            color: var(--secondary-color);
          }
        }

        .auto-complete {
          color: var(--secondary-color);
        }

        a {
          color: inherit;
          &:hover {
            background: none;
            color: var(--primary-color);
          }

          &.disabled {
            text-decoration: line-through;
            cursor: not-allowed;

            &:hover {
              background: none;
              color: inherit;
            }
          }
        }
      }
    }

    .pager {
      input {
        font-family: inherit;
        font-size: var(--global-font-size);
        border: none;
        border-bottom: dashed 1px var(--secondary-color);
        margin: 0;
        width: 2em;
        text-align: center;
        padding: 0;

        &:focus {
          outline: none;
        }

        &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
          // opacity: 1;
        }
        &[type="number"] {
          -moz-appearance: textfield;
        }
      }
    }
  }
}