.post-page {
  .post-info-box {
    margin-top: 0;

    .tag {
      &:hover {
        text-decoration: underline;
        background: inherit;
      }
    }

    code::after {
      content: none;
    }
  }

  .post-content {
    .content {
      margin: 0;
      width: 100%;
    }
  }

  .footer-line {
    margin: calc(var(--global-space) * 3) 0;
  }

  .comments {
    position: relative;
    top: -16px;
  }
}